<template>
  <div>
    <a-modal
      centered
      width="700px"
      :visible="show"
      :title="$t('home.confirmInfo')"
      on-ok="handleOk"
      @cancel="handleCancel"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk"> 确认 </a-button>
      </template>
      <a-descriptions bordered size="small" :column="1" class="infoBox">
        <a-descriptions-item :label="$t('coConfig.coTax')">
          <input type="text" v-model="baseInfo.taxIdNumber" :maxLength="20" />
        </a-descriptions-item>
        <a-descriptions-item :label="$t('coConfig.currency')">
          <a-select :placeholder="$t('customer.currency')" v-model="baseInfo.currency">
            <a-select-option v-for="(item, index) of currency" :key="index" :value="item.dictValue">{{
              item.dictLabel.split(';')[0]
            }}</a-select-option>
          </a-select>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('coConfig.company_name')">
          <input type="text" v-model="baseInfo.name" :maxLength="30" />
        </a-descriptions-item>
        <a-descriptions-item :label="$t('coConfig.company_address')">
          <input type="text" v-model="baseInfo.address" :maxLength="50" />
        </a-descriptions-item>
      </a-descriptions>
      <NeoTips :label="$t('managerInfo.tip_label')" :text="$t('home.remakes')" class="remarks" />
    </a-modal>
    <div v-show="showMask" class="ant-modal-mask"></div>
  </div>
</template>

<script>
import NeoTips from '@/components/common/NeoTips'
const { validate } = window.$g
export default {
  name: 'ConfirmCusInfo',
  components: { NeoTips },
  data() {
    return {
      visible: false,
      loading: false,
      // 公司基本信息
      baseInfo: {},
    }
  },
  created() {
    this.visible = true
  },
  methods: {
    handleCancel() {
      this.visible = false
      this.baseInfo = { ...this.cusAgencyInfo }
    },
    // 处理企业信息编辑
    async handleOk() {
      const { taxIdNumber, name, currency, address } = this.baseInfo
      if (
        this.$g.isEmpty(taxIdNumber) ||
        this.$g.isEmpty(name) ||
        this.$g.isEmpty(currency) ||
        this.$g.isEmpty(address)
      ) {
        this.$message.error(this.$t('customerConfig.info_empty'))
        return
      }
      if (!validate.taxIdNumber.pattern.test(taxIdNumber)) {
        this.$message.error(validate.taxIdNumber.message)
        return
      }
      const taxIdNumberExisted = await this.validatorCheckByTaxIdNumber()
      console.log('taxIdNumberExisted:' + taxIdNumberExisted)
      if (taxIdNumberExisted) {
        this.$message.error(this.$t('newCompany.cus_tax_existed'))
        return
      }
      const cusNameExisted = await this.validatorCheckByCusName()
      console.log('cusNameExisted:' + cusNameExisted)
      if (cusNameExisted) {
        this.$message.error(this.$t('newCompany.cus_entityname_existed'))
        return
      }
      this.loading = true
      try {
        const formData = { ...this.baseInfo, status: 1, entityName: name }
        await this.$http('cusInfoUpdate', formData)
        const cusAgencyInfo = await this.$http({ key: 'getCusAgencyInfo', params: { id: this.baseInfo.id } })
        // vuex
        this.$store.commit('app/update', { cusAgencyInfo: cusAgencyInfo })
        setTimeout(() => {
          this.visible = false
          this.loading = false
        }, 1000)
        // 刷新home页数据
        this.$bus.$emit('refresh_home_page')
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    getCurrency(val) {
      return this.$store.getters['app/getDictLabel']('CURRENCY', val).split(';')[0]
    },
    // 校验税号是否存在
    async validatorCheckByTaxIdNumber() {
      if (this.cusAgencyInfo.taxIdNumber && this.cusAgencyInfo.taxIdNumber === this.baseInfo.taxIdNumber) {
        return false
      }
      try {
        const data = await this.$http('checkByTaxIdNumber', { taxIdNumber: this.baseInfo.taxIdNumber })
        if (data) {
          return true
        } else {
          return false
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 校验实体名是否存在
    async validatorCheckByCusName() {
      if (this.cusAgencyInfo.name && this.cusAgencyInfo.name === this.baseInfo.name) {
        return false
      }
      try {
        const data = await this.$http('checkByCusName', { cusName: this.baseInfo.name })
        if (data) {
          return true
        } else {
          return false
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
  watch: {
    $route() {
      this.visible = true
    },
    show(val) {
      console.log(val)
      this.baseInfo = { ...this.$store.state.app.cusAgencyInfo }
    },
  },
  computed: {
    show() {
      return (
        this?.$store?.state?.app?.userInfo?.type === 3 &&
        this?.$store?.state?.app?.cusAgencyInfo?.status === 0 &&
        this.visible
      )
    },
    showMask() {
      return (
        this?.$store?.state?.app?.userInfo?.type === 3 &&
        this?.$store?.state?.app?.cusAgencyInfo?.status === 0 &&
        !this.visible
      )
    },
    cusAgencyInfo() {
      return { ...this.$store.state.app.cusAgencyInfo }
    },
    currency() {
      return this.$store.getters['app/getDictByType']('CURRENCY')
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.ant-modal-content {
  .ant-modal-title {
    font-size: 16px;
    color: @text_color_1;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
  tr {
    input {
      border: 0;
      outline: none;
      width: 100%;
    }
    td {
      .ant-select {
        width: 100%;
      }
    }
  }
  .remarks {
    margin: 20px 0 0;
    color: @text_color_2;
  }
  .ant-modal-footer {
    button {
      padding: 0 30px;
    }
  }
}
/deep/.ant-modal-mask {
  margin-left: 216px;
}
</style>
