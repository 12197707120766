<!--
 * @Author: gaojingran
 * @Date: 2021-03-31 14:08:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-13 10:33:27
 * @Description: sider 菜单
-->
<style lang="less" scoped>
.neo-frame-sider-menu {
  .dot {
    display: inline-block;
    height: 20px;
    min-width: 22px;
    text-align: center;
    line-height: 20px;
    border-radius: 100%;
    font-size: @font_size_1;
    color: #fff;
    background: @warning-color;
    margin-left: 20px;
    transform: scale(0.8);
  }
  /deep/.ant-menu-dark {
    .ant-menu-item,
    .ant-menu-submenu-title {
      margin-top: 0;
      margin-bottom: 0;
    }
    .ant-menu-submenu-open.ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        background-color: @background-color_6 !important;
      }
    }
    .ant-menu-inline.ant-menu-sub {
      box-shadow: none;
      background-color: @background-color_7 !important;
      .ant-menu-item.ant-menu-item-selected {
        color: @primary-color;
      }
    }
  }
}
</style>

<template>
  <div class="neo-frame-sider-menu">
    <a-menu
      mode="inline"
      theme="dark"
      :key="$route.name"
      :default-selected-keys="[$route.name]"
      :defaultOpenKeys="menuListData.map((v) => v.name)"
      @click="handleMenuClick"
    >
      <template v-for="nav in menuListData">
        <!-- 1级 -->
        <a-menu-item v-if="!nav.children || !nav.children.length" :key="nav.name">
          <a-row type="flex" align="middle">
            <NeoIcon :name="getMenuIcon(nav.name)" :size="18" />
            <span class="pl-4">{{ nav.meta.title }}</span>
          </a-row>
        </a-menu-item>
        <!-- 2级 -->
        <a-sub-menu v-if="nav.children && nav.children.length" :key="nav.name">
          <a-row type="flex" align="middle" slot="title">
            <NeoIcon :name="getMenuIcon(nav.name)" :size="18" />
            <span class="pl-4">{{ nav.meta.title }}</span>
            <a-badge v-if="nav.name === 'project' && todoCount > 0" class="ml-2" status="warning" />
          </a-row>
          <a-menu-item v-for="child in nav.children" :key="child.name">
            <span class="pl-3">
              {{ child.meta.title }}
              <!-- <i class="dot" v-if="child.name === 'project_list' && orderCount > 0">{{ orderCount }}</i> -->
              <i
                class="dot"
                v-if="(child.name === 'project_schedule' || child.name === 'Stone_project_schedule') && todoCount > 0"
                >{{ todoCount }}</i
              >
            </span>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SiderMenu',
  props: {
    // 待办count
    todoCount: {
      type: Number,
      default: 0,
    },
    // 订单count
    orderCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('app', {
      menuList: (state) => state.menuList,
    }),
    // 去除isNav = 0
    menuListData() {
      return this.filterMenu(this.menuList)
    },
  },
  methods: {
    handleMenuClick(obj) {
      const { key } = obj
      console.log('obj :>> ', obj)
      console.log('key :>> ', key)
      this.$router.push({ name: key })
    },
    getMenuIcon(name) {
      let iconName = name?.split('_')[0]
      if (Object.keys(this.$g.customers).includes(iconName)) {
        iconName = name.split('_')[1]
      }
      if (iconName === this.$route.name.split('_')[0]) {
        return `${iconName}_o`
      } else {
        return iconName
      }
    },
    filterMenu(menu) {
      return menu
        .map((m) => {
          if (m.meta.isNav === 0) {
            return false
          } else {
            return {
              ...m,
              children: m.children ? this.filterMenu(m.children) : undefined,
            }
          }
        })
        .filter((v) => v)
    },
  },
}
</script>
