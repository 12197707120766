<!--
 * @Author: gaojingran
 * @Date: 2021-03-31 11:17:57
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-13 13:55:47
 * @Description: Nav Tab
-->
<style lang="less" scoped>
.nav-tab {
  position: sticky;
  z-index: 98;
  top: 0;
  right: 0;
  left: @sider-width;
  height: @nav-tab-height;
  background-color: @background-color_2;
  /deep/.ant-tabs-bar {
    border-bottom: none !important;
  }
  /deep/.ant-tabs-nav-container {
    height: @nav-tab-height !important;
    .ant-tabs-tab {
      line-height: @nav-tab-height !important;
      height: @nav-tab-height !important;
      border: none;
      background-color: #fff;
      color: @text_color_2;
      &.ant-tabs-tab-active {
        background-color: #fff;
        font-weight: normal;
        color: @primary-color;
        background-color: #e9f4ff;
      }
    }
  }
  .tab-content {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      margin-right: 8px;
    }
    .icon {
      color: @text_color_3;
      margin-right: 0px;
    }
    &:hover {
      .icon {
        color: @primary-color;
      }
    }
  }
}
</style>

<template>
  <div class="nav-tab">
    <a-tabs :activeKey="activeKey" hide-add type="editable-card">
      <a-tab-pane v-for="route in tabList" :key="route.uuid">
        <template v-slot:tab>
          <div class="tab-content">
            <span class="title" @click="handleJump(route)">{{ getTabName(route) }}</span>
            <a-icon class="icon" type="close-circle" theme="filled" @click.native="handleRemove(route)" />
          </div>
        </template>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  name: 'NavTab',
  props: {
    // tab 列表
    tabList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activeKey() {
      return this.tabList.find((t) => t.name === this.$route.name && isEqual(t.query, this.$route.query))?.uuid || ''
    },
  },
  methods: {
    getTabName(route) {
      if (route.query.extraName) {
        return `${route.meta.title}-${route.query.extraName}`
      }
      return route.meta.title
    },
    handleJump(route) {
      this.$router.push({
        name: route.name,
        params: route.params,
        query: route.query,
      })
    },
    handleRemove(route) {
      const result = this.tabList.filter((v) => v.uuid !== route.uuid)
      this.$emit('update:tabList', result)
      if (result.length) {
        const r = result[result.length - 1]
        this.$router.push({
          name: r.name,
          params: r.params,
          query: r.query,
        })
      } else {
        this.$router.push({ name: 'main' })
      }
    },
  },
}
</script>
